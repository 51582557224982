import { effect, inject, Inject, Injectable, Injector, signal, Signal, WritableSignal } from '@angular/core';
import { FeatureKey } from '@app/states/experiments/constants/feature-key.const';
import { ExperimentsState } from '@app/states/experiments/states/experiments.state';
import { YoomoneyWidgetCardDataErrorCode } from '@app/states/virtual-card/constants/yoomoney-widget/yoomoney-widget-card-data-error-code.const';
import { IYoomoneyWidgetAuthCode } from '@app/states/virtual-card/interfaces/yoomoney-widget/yoomoney-widget-auth-code.interface';
import { IYoomoneyWidgetChangePin } from '@app/states/virtual-card/interfaces/yoomoney-widget/yoomoney-widget-change-pin.interface';
import { IYoomoneyWidgetError } from '@app/states/virtual-card/interfaces/yoomoney-widget/yoomoney-widget-error.interface';
import { IYoomoneyWidgetTopUp } from '@app/states/virtual-card/interfaces/yoomoney-widget/yoomoney-widget-top-up.interface';
import { IYoomoneyWidgetTransfer } from '@app/states/virtual-card/interfaces/yoomoney-widget/yoomoney-widget-transfer.interface';
import { Store } from '@ngxs/store';
import { IEnvironment } from 'projects/web-bankir-app/src/environments/environment.interface';
import { from, Observable } from 'rxjs';
import { YoomoneyWalletWidgetMock } from '../helpers/yoomoney-wallet-widget-mock.helper';
import { IYoomoneyWidget } from '../interfaces/yoomoney-widget/yoomoney-widget.interface';

@Injectable({
  providedIn: 'root',
})
export class YoomoneyWalletWidgetService {
  private store: Store = inject(Store);
  private injector: Injector = inject(Injector);

  private yooMoneyWalletWidget: IYoomoneyWidget;

  private emulation$: Signal<boolean> = this.store.selectSignal(
    ExperimentsState.featureValue(FeatureKey.YoomoneyWidgetEmulation)
  );

  private initialized$: WritableSignal<boolean> = signal(false);

  constructor(@Inject('environment') private environment: IEnvironment) {
    effect(() => {
      if (this.initialized$() && this.emulation$()) {
        this.init();
      }
    });
  }

  public init(): void {
    this.initialized$.set(true);

    if (this.emulation$()) {
      this.yooMoneyWalletWidget = new YoomoneyWalletWidgetMock(this.injector);
      return;
    }

    if (typeof YooMoneyWalletWidget === 'undefined') {
      const yooMoney = document.createElement('script');
      yooMoney.type = 'text/javascript';
      yooMoney.async = true;
      yooMoney.src = 'https://yoomoney.ru/partner-wallet-widget/v1/widget.js';
      const head = document.getElementsByTagName('head')[0];
      head.appendChild(yooMoney);

      yooMoney.addEventListener('load', () => {
        this.yooMoneyWalletWidget = new YooMoneyWalletWidget({
          ...this.environment.yoomoneyWidgetData,
          customization: {
            colors: {
              controlPrimary: '#FFDE52',
              controlPrimaryHover: '#FFC700',
              controlPrimaryContent: '#1D2431',
              link: '#0274D4'
            },
            font: {
              name: 'roboto'
            }
         }
        });
      });
    }
  }

  public getAuthCode(url: string): Observable<IYoomoneyWidgetAuthCode> {
    return from<Observable<IYoomoneyWidgetAuthCode>>(
      this.yooMoneyWalletWidget.getAuthCode({ authorizationEndpoint: url })
    );
  }

  /** В Promise объект возвращается только при неуспешном выполнении метода. */
  public displayCardData(
    widgetToken: string,
    cardId: string
  ): Observable<IYoomoneyWidgetError<YoomoneyWidgetCardDataErrorCode>> {
    return from<Observable<IYoomoneyWidgetError<YoomoneyWidgetCardDataErrorCode>>>(
      this.yooMoneyWalletWidget.displayCardData({
        widgetToken,
        cardId,
      })
    );
  }

  public changePin(widgetToken: string, cardId: string): Observable<IYoomoneyWidgetChangePin> {
    return from<Observable<IYoomoneyWidgetChangePin>>(this.yooMoneyWalletWidget.changePin({ widgetToken, cardId }));
  }

  public topUp(widgetToken: string): Observable<IYoomoneyWidgetTopUp> {
    return from<Observable<IYoomoneyWidgetTopUp>>(this.yooMoneyWalletWidget.topUp({ widgetToken }));
  }

  public transfer(widgetToken: string): Observable<IYoomoneyWidgetTransfer> {
    return from<Observable<IYoomoneyWidgetTransfer>>(this.yooMoneyWalletWidget.transfer({ widgetToken }));
  }
}
