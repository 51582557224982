import { Injector } from '@angular/core';
import { ModalActions } from '@app/states/modal/states/modal.actions';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { map, Observable, switchMap } from 'rxjs';
import { YoomoneyWidgetCardDataErrorCode } from '../constants/yoomoney-widget/yoomoney-widget-card-data-error-code.const';
import { IYoomoneyWidgetAuthCode } from '../interfaces/yoomoney-widget/yoomoney-widget-auth-code.interface';
import { IYoomoneyWidgetChangePin } from '../interfaces/yoomoney-widget/yoomoney-widget-change-pin.interface';
import { IYoomoneyWidgetError } from '../interfaces/yoomoney-widget/yoomoney-widget-error.interface';
import { IYoomoneyWidgetSuccess } from '../interfaces/yoomoney-widget/yoomoney-widget-success.interface';
import { IYoomoneyWidgetTopUp } from '../interfaces/yoomoney-widget/yoomoney-widget-top-up.interface';
import { IYoomoneyWidgetTransfer } from '../interfaces/yoomoney-widget/yoomoney-widget-transfer.interface';
import { IYoomoneyWidget } from '../interfaces/yoomoney-widget/yoomoney-widget.interface';

export class YoomoneyWalletWidgetMock implements IYoomoneyWidget {
  private store: Store = this.injector.get(Store);
  private actions$: Actions = this.injector.get(Actions);

  constructor(private injector: Injector) {}

  public getAuthCode(data: { authorizationEndpoint: string }): Observable<IYoomoneyWidgetAuthCode> {
    return this.mock([
      {
        label: 'Передать код подтверждения',
        type: 'success',
        value: {
          status: 'success',
          data: {
            authorizationCode: 'OnF6WUMZ4HZUFLEzpxiz_D7a6-EHIwvoJQk8aT-aozIW7O33jN4plGU7D23XZiIJ',
          },
        },
      },
      {
        label: 'Этому пользователю запрещена регистрация в ЮMoney',
        type: 'error',
        value: {
          status: 'error',
          data: {
            errorCode: 'Forbidden',
          },
        },
      },
      {
        label: 'Пользователь закрыл окно регистрации или входа в ЮMoney',
        type: 'error',
        value: {
          status: 'error',
          data: {
            errorCode: 'GenerateOauthCodeWindowClosed',
          },
        },
      },
      {
        label: 'Некорректный идентификатор приложения',
        type: 'error',
        value: {
          status: 'error',
          data: {
            errorCode: 'InvalidClientCredentials',
          },
        },
      },
      {
        label: 'Сессия истекла',
        type: 'error',
        value: {
          status: 'error',
          data: {
            errorCode: 'LoginProcessDataExpired',
          },
        },
      },
      {
        label: 'Превышено максимальное кол-во попыток входа',
        type: 'error',
        value: {
          status: 'error',
          data: {
            errorCode: 'MaxWrongPasswordAttemptsReached',
          },
        },
      },
      {
        label: 'Некорректный processId',
        type: 'error',
        value: {
          status: 'error',
          data: {
            errorCode: 'ProcessNotFound',
          },
        },
      },
      {
        label: 'Неизвестный errorCode',
        type: 'error',
        value: {
          status: 'error',
          data: {
            errorCode: 'UnknownErrorCode',
          },
        },
      },
      {
        label: 'Отсутствует errorCode',
        type: 'error',
        value: {
          status: 'error',
        },
      },
      {
        label: 'Неизвестный status',
        type: 'error',
        value: {
          status: 'unknown',
        },
      },
      {
        label: 'Неизвестный payload',
        type: 'error',
        value: {
          payload: 'unknown',
        },
      },
    ]);
  }

  public displayCardData(data: {
    widgetToken: string;
    cardId: string;
  }): Observable<IYoomoneyWidgetError<YoomoneyWidgetCardDataErrorCode>> {
    return this.mock([
      {
        label: 'Данные карты (ответ не предполагается)',
        type: 'success',
        value: {
          status: 'success',
        },
      },
      {
        label: 'Профиль заблокирован',
        type: 'error',
        value: {
          status: 'error',
          data: {
            errorCode: 'AccountOperationsBlocked',
          },
        },
      },
      {
        label: 'Карта не найдена',
        type: 'error',
        value: {
          status: 'error',
          data: {
            errorCode: 'CardNotFound',
          },
        },
      },
      {
        label: 'Истек срок действия токена',
        type: 'error',
        value: {
          status: 'error',
          data: {
            errorCode: 'LoginProcessDataExpired',
          },
        },
      },
      {
        label: 'Неизвестный errorCode',
        type: 'error',
        value: {
          status: 'error',
          data: {
            errorCode: 'UnknownErrorCode',
          },
        },
      },
      {
        label: 'Отсутствует errorCode',
        type: 'error',
        value: {
          status: 'error',
        },
      },
      {
        label: 'Неизвестный status',
        type: 'error',
        value: {
          status: 'unknown',
        },
      },
      {
        label: 'Неизвестный payload',
        type: 'error',
        value: {
          payload: 'unknown',
        },
      },
    ]);
  }

  public changePin(data: { widgetToken: string; cardId: string }): Observable<IYoomoneyWidgetChangePin> {
    return this.mock([
      {
        label: 'Пин-код изменён (ответ не предполагается)',
        type: 'success',
        value: {
          status: 'success',
        },
      },
      {
        label: 'Превышен лимит изменения пин-кода',
        type: 'error',
        value: {
          status: 'error',
          data: {
            errorCode: 'ChangePinLimitExceeded',
          },
        },
      },
      {
        label: 'Истек срок действия токена',
        type: 'error',
        value: {
          status: 'error',
          data: {
            errorCode: 'LoginProcessDataExpired',
          },
        },
      },
      {
        label: 'Неизвестный errorCode',
        type: 'error',
        value: {
          status: 'error',
          data: {
            errorCode: 'UnknownErrorCode',
          },
        },
      },
      {
        label: 'Отсутствует errorCode',
        type: 'error',
        value: {
          status: 'error',
        },
      },
      {
        label: 'Неизвестный status',
        type: 'error',
        value: {
          status: 'unknown',
        },
      },
      {
        label: 'Неизвестный payload',
        type: 'error',
        value: {
          payload: 'unknown',
        },
      },
    ]);
  }

  public topUp(data: { widgetToken: string }): Observable<IYoomoneyWidgetTopUp> {
    return this.mock([
      {
        label: 'Баланс пополнен (ответ не предполагается)',
        type: 'success',
        value: {
          status: 'success',
        },
      },
      {
        label: 'Пользователь закрыл окно пополнения баланса',
        type: 'error',
        value: {
          status: 'error',
          data: {
            errorCode: 'TopUpWindowClosed',
          },
        },
      },
      {
        label: 'Истек срок действия токена',
        type: 'error',
        value: {
          status: 'error',
          data: {
            errorCode: 'LoginProcessDataExpired',
          },
        },
      },
      {
        label: 'Неизвестный errorCode',
        type: 'error',
        value: {
          status: 'error',
          data: {
            errorCode: 'UnknownErrorCode',
          },
        },
      },
      {
        label: 'Отсутствует errorCode',
        type: 'error',
        value: {
          status: 'error',
        },
      },
      {
        label: 'Неизвестный status',
        type: 'error',
        value: {
          status: 'unknown',
        },
      },
      {
        label: 'Неизвестный payload',
        type: 'error',
        value: {
          payload: 'unknown',
        },
      },
    ]);
  }

  public transfer(data: { widgetToken: string }): Observable<IYoomoneyWidgetTransfer> {
    return this.mock([
      {
        label: 'Перевод выполнен (ответ не предполагается)',
        type: 'success',
        value: {
          status: 'success',
        },
      },
      {
        label: 'Пользователь превысил лимит ЮMoney при переводе',
        type: 'error',
        value: {
          status: 'error',
          data: {
            errorCode: 'TransferLimitExceeded',
          },
        },
      },
      {
        label: 'Пользователь закрыл окно перевода',
        type: 'error',
        value: {
          status: 'error',
          data: {
            errorCode: 'TransferWindowClosed',
          },
        },
      },
      {
        label: 'Истек срок действия токена',
        type: 'error',
        value: {
          status: 'error',
          data: {
            errorCode: 'LoginProcessDataExpired',
          },
        },
      },
      {
        label: 'Неизвестный errorCode',
        type: 'error',
        value: {
          status: 'error',
          data: {
            errorCode: 'UnknownErrorCode',
          },
        },
      },
      {
        label: 'Отсутствует errorCode',
        type: 'error',
        value: {
          status: 'error',
        },
      },
      {
        label: 'Неизвестный status',
        type: 'error',
        value: {
          status: 'unknown',
        },
      },
      {
        label: 'Неизвестный payload',
        type: 'error',
        value: {
          payload: 'unknown',
        },
      },
    ]);
  }

  private mock<T = IYoomoneyWidgetSuccess | IYoomoneyWidgetError>(btns: any[]): Observable<T> {
    return this.store
      .dispatch(
        new ModalActions.OpenModal({
          route: ['yoomoney-wallet-widget-mock'],
          data: btns,
        })
      )
      .pipe(
        switchMap(() => this.actions$),
        ofActionSuccessful(ModalActions.CloseModal),
        map((action) => action.payload as T)
      );
  }
}
